.component-wrapper {
	display: grid;
}

.base-component {
	align-self: center;
	justify-self: center;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.media-container {
	height: auto;

	display: flex;
	justify-content: center;
	align-items: center;
}

.base-component-text-container {
	display: flex;
	gap: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.companies-component-text-container {
	display: flex;
	gap: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.base-component-text-container-wide {
	display: flex;
	gap: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span {
		text-align: center !important;
	}
}

.base-component-span {
	margin: 0;
	font-weight: 300;
}

.base-component-list {
	margin: 0;

	display: flex;
	gap: 20px;
	flex-direction: column;

	font-weight: 300;
}

.base-component-list-item {
	font-weight: 300;
}

@media screen and (min-width: 1050px) {
	.base-component {
		width: min(85vw, 1440px);

		gap: 50px;
	}

	.media-container {
		width: min(40vw, 500px);
		height: auto;

		img {
			height: auto;
			width: 100%;
		}
	}

	.square-video-container {
		height: min(40vw, 500px);
	}

	.base-component-text-container {
		width: min(40vw, 500px);

		gap: 22px;
	}

	.companies-component-text-container {
		width: min(50vw, 720px);

		gap: 22px;
	}

	.base-component-span {
		font-size: 23px;
	}

	.base-component-list {
		padding-left: 20px;

		gap: 16px;

		font-size: 20px;
	}

	.base-component-text-container-wide {
		width: min(60vw, 750px);
	}
}

@media screen and (min-width: 890px) and (max-width: 1050px) {
	.base-component {
		width: 85vw;

		gap: 45px;

	}

	.media-container {
		width: min(40%, 500px);
		height: min(40%, 500px);

		img {
			height: auto;
			width: 100%;
		}
	}

	.base-component-span {
		font-size: 20px;
	}

	.base-component-text-container {
		width: min(40%, 500px);
	}

	.base-component-text-container-wide {
		width: min(60vw, 750px);
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) and (orientation: portrait) {
	.base-component {
		width: 85vw;

		gap: 35px;
		flex-direction: column !important;
	}

	.media-container {
		width: 80vw;
		height: 60vw;

		img {
			width: 100%;
			height: auto;
		}
	}

	.base-component-text-container {
		width: 80vw;

		gap: 20px;
		justify-content: center;
		align-items: center;
	}

	.base-component-span {
		font-size: 17px;
		text-align: center;
	}

	.base-component-text-container-wide {
		width: 80vw;
	}
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.base-component {
		width: 95vw;

		gap: 30px;
		flex-direction: column !important;
	}

	.media-container {
		width: 100vw;
		height: 56.25vw;

		img {
			width: auto;
			height: 80%;
		}
	}

	.square-video-container {
		width: 100%;
		height: 100vw;
	}


	.base-component-text-container {
		width: 80vw;

		gap: 20px;
		justify-content: center;
		align-items: center;
	}

	.base-component-span {
		font-size: 17px;
		text-align: center;
	}

	.base-component-text-container-wide {
		width: 80vw;
	}
}

@media screen and (max-width: 890px) and (orientation: landscape) {
	.base-component {
		width: 85vw;

		.media-container {
			width: 40vw;
			height: 40vw;

			img {
				height: auto;
				width: 100%;
			}
		}
	}

	.base-component-text-container {
		width: min(40vw, 500px);
		height: 100%;

		gap: 15px;
	}

	.base-component-list {
		font-size: 13px;
	}

	.base-component-span {
		font-size: 13px;
	}

	.base-component-list {
		padding-left: 20px;

		gap: 10px;

		font-size: 17px;
	}
}