.whatIsRapidComponent {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	background: #FFFFFF;

}

.whatIsRapidSpansContainer {
	width: 37%;

	display: flex;
	gap: 20px;
	flex-direction: column;
}

@media only screen and (max-width: 1300px) and (min-width: 800px) {
	.whatIsRapidSpansContainer {
		width: 40%;
	}
}

.whatIsRapidSpansContainer a {
	text-align: left;
	color: #000000;
	font-style: normal;
}

.whatIsRapidSpansContainer ul {
	display: grid;
	gap: 10px;
}

.whatIsRapidHeader {
	line-height: 56px;
	font-size: 40px;
	font-weight: 500;
}

.whatIsRapidSpans {
	font-weight: 300;
	font-size: 17px;
	line-height: 26px;
}

.whatIsRapidSpansContainer li {
	display: flex;
	gap: 20px;
}

.whatIsRapidVideoContainer {
	width: 40%;
}

.mobile .whatIsRapidComponent {
	flex-direction: column;
}

.mobile .whatIsRapidSpansContainer {
	width: 90%;

	padding: 0px !important;
}

.react-live-home-player {
	width: 100%;
	height: 100%;
}