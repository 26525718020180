.downloadAppComponent {
	margin-top: -5px;
	padding: 0 3vw;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	justify-content: space-around;

	background: #1EA9CD;
}

.downloadAppComponent a {
	font-style: normal;
	font-weight: 500;
	text-align: justify;
	color: #FFFFFF;
	opacity: 0.8;
	display: flex;
	align-items: center;
}

.appMarketTitle {
	color: #FFF;
	text-align: justify;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Inter;
	font-size: 25px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.5px;
}

.mobile .appMarketTitle {
	display: flex;
	align-items: center;
	justify-content: center;

	text-align: center;
	line-height: 1.5em;
	font-size: 1rem;
}

.appMarket {
	display: flex;
	/* width: 16%; */
	padding: 15px 0px;
}

.appMarketSpans {
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
	justify-content: center;
}

.appMarketData {
	display: flex;
	gap: 10px;
}

.appMarketName {
	font-size: 14px;
	line-height: 26px;
}

.appMarketDataSpan {
	font-size: 14px;
	line-height: 20px;
}


/* Responsive Settings */

.mobile .downloadAppComponent {
	gap: 0px;
}

.mobile .appMarketDataStars {
	width: 100%;
}

.tablet .appMarketLogo {
	height: 60%;
}

.tablet .appMarketDataStars {
	width: 100%;
}

.mobile .appMarket {
	width: auto;
	/* width: 40%; */
}

.mobile .appMarketName {
	font-size: 10px;
}

.mobile .appMarketDataStars {
	width: 50%;
}