.functionsComponent {
	padding-bottom: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.functionsComponentHeaderContainer {
	display: flex;
	gap: 30px;
	flex-direction: column;
	align-items: center;
}

.functionsComponentHeader {
	margin: 0 10px;

	color: #000;
	text-align: center;
	font-size: 45px;
	font-style: normal;
	font-weight: 700;
	font-family: Inter;
	letter-spacing: -.5px;
}

.mobile .functionsComponentHeader {
	font-size: 2rem;
	font-weight: 600;
	letter-spacing: 0px;
}

.functionsContainer {
	width: 100%;

	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: center;
}


.mobile .functionsContainer {
	gap: 30px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.functionsItem {
	width: 30%;
	display: flex;
	gap: 30px;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	padding-top: 25px;
}

.moreFunctionsButton {
	width: 120px;
	height: 35px;
	flex-shrink: 0;
	border-radius: 9px;
	background: #1EA9CD;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.moreFunctionsText {
	color: #FFF;
	text-align: center;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.functionsImage {
	width: 30%;
	height: 65px;
}

.mobile .functionsImage {
	width: 50%;
}

.functionsItemHeader {
	color: #000;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	vertical-align: middle;
	display: flex;
	margin-bottom: 0;
	font-family: Inter;
}

.functionsItemName {
	color: #797979;
	text-align: center;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 26px;
	text-align: center;
	width: 90%;

}

.functionsItem.active {
	border: 2px solid #1EA9CD;
	border-radius: 20px;
}

.descriptionContainers {
	padding-bottom: 0;
	width: 100%;
}

.descriptionContainerHeader {
	color: #000;
	text-align: center;
	font-size: 45px;
	font-style: normal;
	font-weight: 700;
	line-height: 56.5px;
	letter-spacing: -1px;
}

.descriptionContainerDescription {
	color: #000;
	text-align: center;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 26px;
}

.descriptionContainerDescription2 {
	color: #000;
	text-align: justify;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 26px;
}

.descriptionContainerVideo {
	width: 100%;
	height: 60vh;
}

.descriptionInclusiveDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 55%;
	gap: 50px;
}

.descriptionModelViewerDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 75%;
	gap: 50px;
}

.descriptionDiv {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	position: relative;
	padding: 30px 0px;
	gap: 30px;
}

.descriptionSpansDiv {
	display: flex;
	flex-direction: column;
	width: 35%;
	gap: 30px;
	align-items: flex-start;
}

.handleBackToTopButton {
	position: fixed;
	z-index: 8;
	right: 3%;
	bottom: 10%;
	width: 4%;
	cursor: pointer;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.472);
	border-radius: 100px;
}

@media screen and (min-width: 890px) {
	.functionsItem {
		width: 30%;
	}	
}

@media screen and (min-width: 600px) and (max-width: 890px){
	.functionsItem {
		width: 40%;
	}

	.functionsItemName {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.functionsItem {
		width: 45%;
	}

	.functionsItemName {
		display: none;
	}
}