.page-banner-wrapper {
	width: 100%;

	display: grid;
}

.page-banner {
	height: auto;

	position: relative;
	justify-self: center;
	align-self: center;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.page-banner-text-container {
	position: relative;

	display: flex;
	gap: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.page-banner-header {
	margin: 0;

	color: #FFF;

	font-family: Inter;
	font-weight: 700;
}

.page-banner-span {
	color: #FFF;

	font-family: Inter;
	font-weight: 400;
}

.home-banner-wrapper {
	width: 100%;

	display: block;
}

.home-banner {
	position: absolute;
	transform: translateX(calc(50vw - 50%));
	z-index: 10;

	.page-banner-span {
		color: #000;
	}
}

#home-banner-video-thumbnail {
	width: 100%;
	height: 100%;

	position: absolute;
	left: 0;
}

@media screen and (min-width: 1050px) {
	.home-banner {
		width: min(85vw, 1440px);
		top: min(15vw, 160px);

		.component-header-normal {
			font-size: min(3vw, 30px);
			line-height: min(3.5vw, 35px);
		}
	}

	.home-overlay {
		width: 30vw;
		height: 100px;

		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

@media screen and (min-width: 890px) and (max-width: 1050px) {
	.home-banner {
		width: 87.5vw;
		top: min(15vw, 160px);

		.component-header-normal {
			font-size: min(3vw, 30px);
			line-height: min(3.5vw, 35px);
		}

		.page-banner-span {
			font-size: 16px;
		}
	}

	.home-overlay {
		width: 30vw;
		height: 100px;

		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

@media screen and (min-width: 890px) {
	.page-banner-wrapper {
		height: min(calc(100vh - 80px), 700px);
	}

	.page-banner {
		width: min(85vw, 1440px);

		flex-direction: row;
	}

	.page-banner-text-container {
		width: min(40vw, 488px);
	}

	.page-banner-header {
		font-size: min(4vw, 40px);
		text-align: left;
	}

	.page-banner-span {
		font-size: min(1.8vw, 23px);
		line-height: min(2.7vw, 34px);
		text-align: left;
	}

	.page-banner-image {
		width: min(35vw, 650px) !important;
		height: min(35vw, 650px) !important;
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) and (orientation: portrait) {
	.page-banner {
		width: 80vw;

		padding: 5vw 0;

		gap: 50px;
		justify-self: center;
		flex-direction: column;
	}

	.page-banner-header {
		font-size: 32px;
		line-height: 40px;
		text-align: center;
	}

	.page-banner-span {
		font-size: 18.24px;
		line-height: 26px;
		text-align: center;
	}

	.page-banner-image {
		width: 70vw !important;
		height: auto !important;
	}

	.home-banner {
		width: 84vw;
		top: min(15vw, 160px);

		.component-header-normal {
			font-size: 24px;
			line-height: 28px;
			text-align: left;
		}

		.page-banner-span {
			font-size: 16px;
			line-height: 20px;
			text-align: left;
		}
	}

	.home-overlay {
		width: 30vw;
		height: 100px;

		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

@media screen and (max-width: 750px) {
	.home-banner {
		top: 130px;
		.page-banner-span {
			display: none !important;
		}
	}
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.page-banner {
		width: 80vw;

		padding: 40px 0;

		gap: 40px;
		justify-self: center;
		flex-direction: column;

		.media-container {
			width: 100% !important;
			height: 100% !important;
		}
	}

	.page-banner-header {
		font-size: 32px;
		line-height: 40px;
		text-align: center;
	}

	.page-banner-span {
		font-size: 18.24px;
		line-height: 26px;
		text-align: center;
	}

	.page-banner-image {
		width: 70vw !important;
		height: auto !important;
	}

	.home-banner {
		width: 84vw;
		top: 110px;

		.component-header-normal {
			font-size: 20px;
			line-height: 24px;
			text-align: left;
		}
	}

	.home-overlay {
		width: 30vw;
		height: 100px;

		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}


@media screen and (max-width: 890px) and (orientation: landscape) {
	.page-banner {
		width: min(80vw, 1440px);

		padding: 5vw 0;

		flex-direction: row;
	}

	.page-banner-text-container {
		width: min(40vw, 488px);
	}

	.page-banner-header {
		font-size: min(4.5vw, 56px);
		line-height: min(5.2vw, 65px);
		text-align: left;
	}

	.page-banner-span {
		font-size: min(1.8vw, 23px);
		line-height: min(2.7vw, 34px);
		text-align: left;
	}

	.page-banner-image {
		width: min(35vw, 650px);
		height: min(35vw, 650px);
	}

	.home-banner {
		width: 87.5vw;
		top: min(15vw, 160px);

		.component-header-normal {
			font-size: min(3vw, 30px);
			line-height: min(3.5vw, 35px);
			text-align: left;
		}

		.page-banner-span {
			font-size: 16px;
			text-align: left;
		}
	}

	.home-overlay {
		width: 30vw;
		height: 100px;

		display: flex;
		flex-direction: column;
		text-align: left;
		gap: 20px;
	}
}