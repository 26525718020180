.resetPasswordInputContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 8px;
    border: 1px solid #BDBDBD;
    background: var(--color-white, #FFF);
    gap: 10px;
    padding: 10px;
}
.mobile .resetPasswordInputContainer{
    width: 90%;
}