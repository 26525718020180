.why-rapid-component {
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;

	background-color: #EEEEEE;

	.swiper-shading-container {
		width: 80vw;
	}
}


@media screen and (min-width: 890px) {
	.why-rapid-swiper {
		width: 80vw;

		.swiper-slide {
			width: min(30vw, 279px);

			display: flex;
			gap: 30px;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		img {
			width: 30%;
			height: auto;
		}
	}

	.swiper-text-container {
		width: 90%;

		display: flex;
		align-items: center;
		gap: 10px;
		flex-direction: column;

		h3 {
			margin: 0;

			font-family: Nunito;
			font-size: 28px;
			font-weight: 700;
			line-height: 31px;
			letter-spacing: 0em;
			text-align: center;
		}

		span {
			font-family: Inter;
			font-size: 15px;
			font-weight: 300;
			line-height: 21px;
			letter-spacing: 0px;
			text-align: center;
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) {
	.why-rapid-swiper {
		width: 80vw;

		.swiper-slide {
			width: min(30vw, 279px);

			display: flex;
			gap: 30px;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		img {
			width: 30%;
			height: auto;
		}
	}

	.swiper-text-container {
		width: 90%;

		display: flex;
		gap: 10px;
		flex-direction: column;

		h3 {
			margin: 0;

			font-family: Nunito;
			font-size: 28px;
			font-weight: 700;
			line-height: 31px;
			letter-spacing: 0em;
			text-align: center;
		}

		span {
			font-family: Inter;
			font-size: 15px;
			font-weight: 300;
			line-height: 21px;
			letter-spacing: 0px;
			text-align: center;
		}
	}
}


@media screen and (max-width: 600px) {
	.why-rapid-swiper {
		width: 80vw;

		.swiper-slide {
			width: min(30%, 279px);

			display: flex;
			gap: 30px;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		img {
			width: 30%;
			height: auto;
		}
	}

	.swiper-text-container {
		width: min(60vw, 279px);

		display: flex;
		gap: 10px;
		flex-direction: column;

		h3 {
			margin: 0;

			font-family: Nunito;
			font-size: 28px;
			font-weight: 700;
			line-height: 31px;
			letter-spacing: 0em;
			text-align: center;
		}

		span {
			font-family: Inter;
			font-size: 15px;
			font-weight: 300;
			line-height: 21px;
			letter-spacing: 0px;
			text-align: center;
		}
	}
}