.prouctDetailContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    gap: 50px;
    justify-content: space-evenly;
    padding-bottom: 60px;
}
.mobile .prouctDetailContainer{
    padding-top: 50px !important;
}
.header{
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 52px;
    text-align: justify;
    letter-spacing: -1px;
}
.span{
    text-align: justify;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 80%;
    display: flex;
}
.shadowImage{
    position: absolute;
}
.images{
    display: flex;
    flex-direction: column;
}
.libraryFinalImage{
    width: 82%;
}
.mobile .libraryFinalImage{
    width: 90%;
}

.mainImage{
    width: 100%;
}

.mobile .header{
    font-size: 26px;
}