.brand-column-wrapper {
	width: 100%;
	display: grid;
	gap: 20px;
}

.brand-column {
	width: 100%;

	display: flex;
	gap: 50px;
	flex-direction: column;
	align-items: center;

	img {
		width: 100%;
		height: 100%;
	}
}

.brand-logo-wrapper {
	width: 80%;
	height: auto;

	display: flex;
	justify-content: center;
	align-items: center;
}

.brand-column-divider {
	height: 100%;
	width: 0;

	border: 1px solid #B7B7B7;
}

@media screen and (min-width: 890px) {
	.brand-column-wrapper {
		grid-template-columns: 1fr 0 1fr 0 1fr 0 1fr;
	}
}

@media screen and (min-width: 890px) and (max-width: 1050px) {
	.brand-column-wrapper {
		width: 95%;
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) {
	.brand-column-wrapper {
		width: 95%;

		grid-template-columns: 1fr 0 1fr 0 1fr;
	}
}

@media screen and (max-width: 600px) {
	.brand-column-wrapper {
		width: 90%;

		grid-template-columns: 1fr 0 1fr;
	}
}