.footerMainContainer {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;

	overflow: hidden;
	background-image: url("../../../public/assets/img/footerImages/footer.webp");
	background-size: cover;
	background-repeat: no-repeat;
}

.footerFirstContainer {
	width: 100%;
	height: 250px;

	position: relative;
	padding: 15px 0;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.footer-text-container {
	width: min(60vw, 762.5px);

	display: flex;
	gap: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		margin: 0;

		text-align: center;
		font-size: 150%;
		font-weight: 700;
		color: #fff;
	}

	span {
		margin: 0;

		text-align: center;
		font-size: 100%;
		font-weight: 400;
		color: #fff;
	}

	@media screen and (max-width: 800px) {
		span {
			display: none;
		}
	}
}

.footerSecondContainer {
	width: 100%;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	background-color: #CFECF0;
}

.buttons {

	display: flex;
	gap: 30px;
	flex-direction: row;
}

.buttons a {
	text-decoration: none;
}

.button1 {
	height: 20px;
	width: 200px;

	padding: 20px;
	border-radius: 9px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1.5px solid #FFFFFF;
	border-radius: 9px;

	color: #1EA9CD;
	background-color: #FFFFFF;
	text-decoration: none;
	text-align: center;
	font-family: 'DM Sans' !important;

	cursor: pointer;
}

.button2 {
	height: 20px;
	width: 200px;

	padding: 20px;
	border: 1.5px solid #FFFFFF;
	border-radius: 9px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: #FFFFFF;
	text-align: center;
	text-decoration: none;
	font-family: 'DM Sans' !important;

	cursor: pointer;
}

.joinUsText {
	width: 80vw;
	margin-top: 40px;

	color: #000000;
	text-align: center;
	line-height: 34px;
	font-style: normal;
	font-weight: 700;
	font-size: 27px;
}

.secondContainerSpanText {
	width: 80%;

	margin: 10px 0;

	color: #555;
	text-align: center;
	line-height: 26px;
	letter-spacing: -1px;
	font-style: normal;
	font-weight: 300;
	font-size: 17px;

	mix-blend-mode: normal;
}

.mobile .secondContainerSpanText {
	font-size: 1rem;
}

.mailSubmit {
	margin: 10px 0;

	display: flex;
	gap: 15px;
	flex-direction: row;
	justify-content: center;
}

.footerInput {
	width: 20vw;
	height: 55px;

	padding: 0px 2vw;
	border: 2px solid #C1C1C1;
	border-radius: 9px;

	background: rgba(255, 255, 255, 0.5);
}

.subscribeButton {
	height: 58px;
	border-radius: 9px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #1EA9CD;

	cursor: pointer;
}

.mobile .subscribeButton {
	width: 45%;
}

.subscribeButton:hover {
	background: #15768f;
}

.footerErrorMessage {
	color: #F00;
	font-feature-settings: 'clig' off, 'liga' off;

	line-height: 115%;
	font-family: Inter;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
}

.subscribeText {
	padding: 0px 20px;

	color: #FFFFFF;
	text-align: center;
	line-height: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
}

.footerInput::placeholder {
	display: flex;
	align-items: center;

	color: #C1C1C1;
	line-height: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
}

.social-media-links {
	position: relative;
	margin: 10px 0;

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(1, 1fr);
	gap: 10px;

	a {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}
}

.footer-links-divider {
	width: 100vw;
	height: .5px !important;

	background-color: #797979;
	margin: 15px 0 10px 0;
}

.copyright-text {
	margin-bottom: 10px;

	color: #555;

	text-align: center;
	font-size: small;
	font-style: normal;
	font-weight: 500;
}

.legal-docs-links {
	position: relative;

	display: inline-flex;
	gap: 10px;
	justify-content: space-evenly;
	align-items: center;

	a {
		color: #333;

		font-style: normal;
		font-weight: 500;
		font-size: 80%;
		text-align: center;
	}
}

.alertOverlay {
	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	overscroll-behavior: contain;

	background-color: rgba(0, 0, 0, 0.5);
}

.mobile .alertOverlay {
	top: 25px;
}

.alertPopup {
	width: 31vw;

	position: relative;
	padding: 20px;
	border-radius: 8px;

	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	text-align: center;

	background-color: white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.oopsImage {
	width: 5.5vw;
	padding-top: 45px;
}

.mobile .oopsImage {
	width: 40vw;
}

.oopsTitle {
	width: 18vw;

	margin-top: 30px;
	margin-bottom: 14px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;

	color: #000;
	text-align: center;
	line-height: 0px;
	font-family: Open Sans;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: -0.587px;
}

.oopsSpan {
	padding-bottom: 45px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;

	color: #727272;
	text-align: center;
	line-height: 23.5px;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.closeIconPopup {
	position: absolute;
	top: 15px;
	right: 15px;

	cursor: pointer;
}

.subscriptionForm {
	height: min(75vh, 800px);
	width: clamp(600px, 53vw, 900px);
	min-height: 400px;
	min-width: 200px;

	position: fixed;
	bottom: 50%;
	left: 50%;
	transform: translate(-50%, 50%);
	padding: 15px;
	z-index: 8;

	display: flex;
	flex-direction: column;
	flex-shrink: 0;

	border: 2px solid rgba(202, 202, 202, 0.50);
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.80);
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.45);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);

	overscroll-behavior: contain;
}

.mobile .subscriptionForm {
	width: 90%;
	height: 80vh;
}

.subscriptionTitle {
	width: 95%;

	padding: 20px 0px;

	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;

	color: #1EA9CD;
	text-align: center;
	font-family: Open Sans;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
}

.mobile .subscriptionTitle {
	padding: 35px 0px;

	color: #1EA9CD;
	text-align: center;
	line-height: 31px;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -1px;
}

.subscriptionSpan {
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: #535353;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 23.5px;
	/* 146.875% */
}

.mobile .subscriptionSpan {
	color: #535353;
	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	padding-bottom: 10px;
	/* mobil body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
	line-height: 19px;
	/* 146.154% */
}

.subscriptionFormContainer {
	margin-top: 50px;
	padding: 0px 45px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.subscriptionRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.formInputContainer {
	display: flex;
	width: 45%;
	flex-direction: column;
}

.mobile .formInputContainer {
	align-items: center;
	width: 90%;
}

.formInputHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: var(--text-color-dark, #252F40);
	/* small title */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 50.5px;
	/* 467.857% */
}

.mobile .formInputHeader {
	align-self: flex-start;
}

.formInput {
	width: auto;
	padding: 13px 9px;
	border-radius: 8px;
	border: 1px solid #BDBDBD;
	background: var(--color-white, #FFF);
	flex-shrink: 0;
}

.mobile .formInput {
	width: 95%;
}
/* 
.requiredPopup {
	color: #F00;
	font-feature-settings: 'clig' off, 'liga' off;
	line-height: 115%;
	font-family: Inter;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
} */

.subscriptionPhoneInputContainer {
	display: flex;
	flex-direction: row;
	border-radius: 8px;
	border: 1px solid #BDBDBD;
	background: var(--color-white, #FFF);
	width: 21vw;
	flex-shrink: 0;
	padding: 13px 9px;

}

.react-tel-input .form-control {
	padding-left: 65px;
}

.phoneCountrySelect {
	width: 3vw;
	border: none;
}

.subscriptionPhoneInput {
	border: none;
	width: 100%;
}

.formSelectInterest {
	border-radius: 8px;
	border: 1px solid #BDBDBD;
	background: var(--color-white, #FFF);
	width: 22.35vw;
	flex-shrink: 0;
	padding: 13px 9px;
}

.formSelectHelpBusiness {
	margin-bottom: 20px;
	border-radius: 8px;
	border: 1px solid #BDBDBD;
	background: var(--color-white, #FFF);
	flex-shrink: 0;
	padding: 13px 9px;
}

.mobile .formSelectInterest,
.formSelectHelpBusiness {
	width: 100%;
}

.formSelectInterestOptions {
	color: var(--text-color-trout, #495057);
	/* H6 Small - 14px/Regular */
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 7px;
	/* 50% */
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	opacity: 0.7;
}

.formSelectHelpBusinessOptions {
	color: var(--text-color-trout, #495057);
	/* H6 Small - 14px/Regular */
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 7px;
	/* 50% */
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	opacity: 0.7;
}

.formInput::placeholder {
	color: var(--text-color-trout, #495057);
	/* H6 Small - 14px/Regular */
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 7px;
	/* 50% */
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	opacity: 0.7;
}

.subscriptionLetter {
	display: flex;
	flex-direction: column;
}

.mobile .subscriptionLetter {
	width: 90%;
	align-self: center;
}

.subscriptionLetterInput {
	padding: 15px 13px;
	position: relative;
	display: flex;
	align-items: center;
	height: 150px;
	flex-shrink: 0;
	border-radius: 8px;
	border: 1px solid #BDBDBD;
	background: var(--color-white, #FFF);
	text-align: left;
	text-overflow: ellipsis;
	resize: none;
}

.subscriptionLetterInput::placeholder {
	position: absolute;
	color: var(--text-color-trout, #495057);
	font-family: Open Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 7px;
	/* 50% */
	opacity: 0.7;
}

.subscriptionSelections {
	display: flex;
	flex-direction: row;
	padding: 5px 45px;
	gap: 20px;
}

.subscriptionSubmitButton {
	width: 70%;
	padding: 12px 48px;
	margin: 25px 45px;
	display: flex;
	align-items: center;
	align-self: center;
	justify-self: center;
	justify-content: center;
	border-radius: 8px;
	background: #1EA9CD;
	/* Button/Default */
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 7px -1px rgba(0, 0, 0, 0.11);
}

.reCaptcha {
	align-items: center;
	justify-content: center;
	padding: 10px 0;
}

.reCaptchaFooter {
	height: 50%;
	position: relative;
}

.subscriptionSubmitText {
	color: var(--text-color-white, #FFF);
	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	/* 128.57% */
}

.footerChoicesText {
	cursor: pointer;
}

.response-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	z-index: 11;
}

/* Responsive Settings */
.mobile .footerChoicesText {
	color: #252525;
	font-feature-settings: 'clig' off, 'liga' off;

	/* mobil body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
	line-height: 19px;
	/* 146.154% */
}

.mobile .alertPopup {
	width: 70%;
}

@media only screen and (max-width: 800px) {
	.buttons {
		width: 300px;

		gap: 10px;
		flex-direction: column;
		justify-content: center;
	}
}

.mobile .footerInput {
	width: 100%;
}

.mobile .mailSubmit {
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}


.mobile .copyrightText {
	text-align: center;
	font-size: 12px;
}

.tablet .subscriptionRow {
	flex-direction: column;
	align-items: flex-start;
}

.mobile .subscriptionRow {
	flex-direction: column;
	align-items: center;
}

.mobile .subscriptionFormContainer {
	/* padding: 0px; */
	height: auto;
}

.mobile .subscriptionSelections {
	padding: 0px;
	width: 90%;
	align-self: center;
}

.mobile .button1 {
	font-size: 15px;
	padding: 20px 0px;
}

.mobile .button1.footer {
	width: 80%;
	padding: 15px 10%;
	font-size: 14px;
}

.mobile .button2 {
	font-size: 11px;
	padding: 20px 0px;

}

.mobile .button2.footer {
	width: 80%;
	padding: 15px 10%;
	font-size: 14px;
	font-weight: 600;
}

.tablet .buttons {
	gap: 10px;
}

.tablet .footerInput {
	width: 25vh;
}

.tablet .mailSubmit {
	width: 85%;
	gap: 20px;
}