.customer-support-component {
	width: 100%;

	display: grid;

	background-color: #E4F3FB;
}

.customer-support-container {
	width: min(80vw, 1440px);

	align-self: center;
	justify-self: center;

	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.customer-support-text-container {
	width: min(40vw, 500px);

	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	h2 {
		margin: 0;

		color: #000000;

		font-size: 45px;
	}

	p {
		margin: 0;

		color: #000000;

		font-size: 17px;
		font-weight: 300;
	}

	.contact-number {
		margin-bottom: 20px;
		color: #1EA9CD;
	}
}

.customer-support-image-container {
	width: min(40vw, 500px);
}

.customer-support-image {
	height: 100%;
	width: auto;
}

@media screen and (min-width: 1050px) {
	.customer-support-container {
		width: min(80vw, 1440px);
	}

	.customer-support-text-container {
		width: min(40vw, 500px);
	}

	.customer-support-image-container {
		width: min(40vw, 500px);
	}

	.customer-support-image {
		height: auto;
		width: 100%;
	}
}

@media screen and (max-width: 1050px) {
	.customer-support-container {
		gap: 30px;
		flex-direction: column;
	}

	.customer-support-text-container {
		width: 100%;

		h2 {
			font-size: 36px;
		}

		p {
			font-size: 16px;
		}
	}

	.customer-support-image-container {
		width: 100%;
		height: min(30%, 300px);

		img {
			height: auto;
			width: 100%;
		}
	}
}