.furniture-partner-component {
	width: 100%;

	display: grid;

	background: #EEE;
}

.furniture-partner-container {
	position: relative;
	justify-self: center;
	align-self: center;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#header-grid {
	position: relative;

	justify-content: center;
	align-items: center;

	text-align: left;
}

#top-quote {
	width: min(8vw, 115px);
	height: min(6vw, 84px);

	position: absolute;
	top: 0;
	left: 0;

	transform: translate(0, -100%);
}

#bottom-quote {
	width: min(5vw, 85px);
	height: min(4vw, 64px);

	position: absolute;
	right: 0;
	bottom: 0;
}

#partner-logo {
	align-self: flex-start;
}

#partner-header {
	justify-self: flex-start;
	align-self: flex-start;

	color: #000000;

	text-align: left;
	font-style: normal;
	font-weight: 700;
}

#partner-span {
	position: relative;

	color: #000000;

	line-height: 25px;
	text-align: left;
	font-style: normal;
	font-size: 1.1rem;
	font-weight: 250;
}

#partner-title {
	position: relative;
	margin: 0;

	color: #000000;

	text-align: left;
	font-style: bold;
}

.partner-video-container {
	width: min(80vw, 1440px);
	height: min(45vw, 810px);

	display: flex;
	flex-direction: column;
	align-items: center;
}

#header-grid {
	display: grid;
	grid-template-columns: .2fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 10px;

	#top-quote {
		grid-area: 1 / 1 / 2 / 2;
	}

	#partner-logo {
		grid-area: 1 / 1 / 2 / 2;
	}

	#partner-header {
		grid-area: 1 / 2 / 2 / 3;
	}

	#bottom-quote {
		grid-area: 1 / 2 / 2 / 3;
	}

	#partner-span {
		grid-area: 2 / 1 / 3 / 3;
	}

	#partner-title {
		grid-area: 3 / 1 / 4 / 3;
	}
}

@media screen and (min-width: 1050px) {
	#header-grid {
		grid-template-rows: 1.4fr 1fr .1fr;
	}

	.furniture-partner-container {
		width: min(80vw, 1440px);

		padding: 50px 0 80px 0;

		gap: 50px;
	}

	#header-grid {
		height: auto;
		width: 100%;
	}

	#partner-logo {
		width: min(13vw, 209px);

		margin-top: 20px;
	}

	#partner-header {
		margin: 20px 0 0 0;

		line-height: 56px;
		font-size: 36px;
	}

	#partner-span {
		width: 90%;

		font-size: 16px;
	}

	#partner-title {
		width: 100%;

		line-height: 25px;
		font-size: 1.1rem;
	}
}

@media screen and (min-width: 890px) and (max-width: 1050px) {
	#header-grid {
		grid-template-rows: 1fr 1fr .1fr;
	}

	.furniture-partner-container {
		width: 80vw;

		padding: 50px 0 80px 0;

		gap: 50px;
	}

	#header-grid {
		height: auto;
		width: 100%;
	}

	#partner-logo {
		width: min(13vw, 209px);

		margin-top: 20px;
	}

	#partner-header {
		margin: 20px 0 0 0;

		line-height: 56px;
		font-size: 36px;
	}

	#partner-span {
		width: 90%;

		font-size: 16px;
	}

	#partner-title {
		width: 100%;

		line-height: 25px;
		font-size: 1.1rem;
	}

	.partner-video-container {
		width: 80vw;
		height: 45vw;
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) {
	#header-grid {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr .5fr 1fr .1fr;
		column-gap: 0;

		#top-quote {
			grid-area: 1 / 1 / 2 / 2;
		}

		#partner-logo {
			grid-area: 2 / 1 / 3 / 2;
		}

		#partner-header {
			grid-area: 1 / 1 / 2 / 2;
		}

		#bottom-quote {
			grid-area: 1 / 1 / 2 / 2;
		}

		#partner-span {
			grid-area: 3 / 1 / 4 / 2;
		}

		#partner-title {
			grid-area: 4 / 1 / 5 / 2;
		}
	}

	.furniture-partner-container {
		width: 80vw;

		padding: 50px 0 80px 0;

		gap: 50px;
	}

	#header-grid {
		height: auto;
		width: 100%;
	}

	#top-quote {
		width: max(8vw, 90px);
		height: max(6vw, 64px);
	}

	#bottom-quote {
		width: max(5vw, 65px);
		height: max(4vw, 44px);
	}

	#partner-logo {
		height: 50%;
		width: auto;

		justify-self: center;
		align-self: center;
	}

	#partner-header {
		margin: 20px 0 0 0;

		line-height: 56px;
		font-size: 36px;
		text-align: center;
	}

	#partner-span {
		width: 100%;

		font-size: 16px;
	}

	#partner-title {
		width: 100%;

		line-height: 25px;
		font-size: 1.1rem;
	}

	.partner-video-container {
		width: 80vw;
		height: 45vw;
	}
}

@media screen and (max-width: 600px) {
	#header-grid {
	grid-template-columns: 1fr;
	grid-template-rows: 1fr .5fr 1fr .1fr;
	column-gap: 0;

	#top-quote {
		grid-area: 1 / 1 / 2 / 2;
	}

	#partner-logo {
		grid-area: 2 / 1 / 3 / 2;
	}

	#partner-header {
		grid-area: 1 / 1 / 2 / 2;
	}

	#bottom-quote {
		grid-area: 1 / 1 / 2 / 2;
	}

	#partner-span {
		grid-area: 3 / 1 / 4 / 2;
	}

	#partner-title {
		grid-area: 4 / 1 / 5 / 2;
	}
}

	.furniture-partner-container {
		width: 80vw;

		padding: 50px 0 80px 0;

		gap: 50px;
	}

	#header-grid {
		height: auto;
		width: 100%;
	}

	#top-quote {
		width: max(8vw, 90px);
		height: max(6vw, 64px);
	}

	#bottom-quote {
		width: max(5vw, 75px);
		height: max(4vw, 54px);
	}

	#partner-logo {
		height: 50%;
		width: auto;

		justify-self: center;
	}

	#partner-header {
		margin: 20px 0 0 0;

		line-height: 56px;
		font-size: 36px;
	}

	#partner-span {
		width: 90%;

		font-size: 16px;
	}

	#partner-title {
		width: 100%;

		line-height: 25px;
		font-size: 1.1rem;
	}

	.partner-video-container {
		width: 100vw;
		height: 56.25vw;
	}
}