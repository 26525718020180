.styled-div-wrapper {
	display: grid;

	justify-content: center;
	align-items: center;
}

.styled-div-list {
	position: relative;
	left: 0;
	padding-left: 20px;
}