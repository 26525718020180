@media screen and (min-width: 890px) {
	.page-margin {
		margin-top: 80px;
	}
}

@media screen and (max-width: 890px) {
	.page-margin {
		margin-top: min(70px, 15vh);
	}
}

figure {
	margin: 0;
}