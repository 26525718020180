.authenticationContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    border-radius: 8px;
    border: 2px solid rgba(202, 202, 202, 0.50);
    background: rgba(255, 255, 255);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.45), 0px 0px 10px 0px rgba(255, 255, 255, 0.70) inset;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    gap: 30px;
    padding: 50px 0px;
    opacity: 1;
    position: relative;
}
.mobile .authenticationContainer{
    width: 90%;
}

.authHeaders{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.authHeader{
    color: #1EA9CD;
    text-align: center;
    font-family: Open Sans;
    font-size: 24.5px;
    font-style: normal;
    font-weight: 600;
}

.authSpan{
    margin-top: 10px;
    color: #535353;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.authHrefText{
    margin-top: 10px;
    color: #535353;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}

.authOptions{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 15px;
}

.optionContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #BDBDBD;
    background: var(--color-white, #FFF);
    width: 100%;
    cursor: pointer;
}
.mobile .optionContainer{
    width: 90%;
}
.optionText{
    color: #848484;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}
.mobile .optionText{
    font-size: 14px;
}

.optionButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 10px 20px;
    width: 100%;
}

.optionLogo{
    width: 10%;
}

.authMailButton{
    border-radius: 8px;
    background: #1EA9CD;
    display: flex;
    cursor: pointer;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.mobile .authMailButton{
    width: 90%;
}

.authMailText{
    color: var(--text-color-white, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding: 10px 20px;
}

.authPrivacyTextContainer{
    width: 50%;
}
.mobile .authPrivacyTextContainer{
    width: 90%;
}
.authPrivacyText{
    color: #979797;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; /* 150% */
}
.authCreateAccountContainer{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    border-radius: 8px;
    border: 2px solid rgba(202, 202, 202, 0.50);
    background: rgba(255, 255, 255);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.45), 0px 0px 10px 0px rgba(255, 255, 255, 0.70) inset;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    gap: 30px;
    padding: 50px 0px;
    opacity: 1;
}
.mobile .authCreateAccountContainer{
    width: 90%;
}
.authCreateAccountHeaders{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.authCreateAccountHeader{
    display: flex;
    gap: 10px;
    flex-direction: row;
    width: 50%;
}
.mobile .authCreateAccountHeader{
    width: 90%;
}
.authCreateAccountHeaderText{
    color: #1EA9CD;
    text-align: center;
    font-family: Open Sans;
    font-size: 24.5px;
    font-style: normal;
    font-weight: 600;
}
.authCreateAccountHeaderSpan{
    width: 50%;
    color: #535353;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.mobile .authCreateAccountHeaderSpan{
    width: 90%;
}
.authCreateAccountInputs{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 15px;
    padding-top: 20px;
}

.authCreateAccountInputContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 8px;
    border: 1px solid #BDBDBD;
    background: var(--color-white, #FFF);
    gap: 10px;
}
.mobile .authCreateAccountInputContainer{
    width: 90%;
}
.authCreateAccountInput{
    width: 90%;
    height: 90%;
    border: none;
}
.authCreateAccountInput:focus{
    border: none;
    box-shadow: none;
    box-shadow: 0 0 0 0 transparent;
    outline: none;
}
.authCreateAccountInput::placeholder{
    color: #848484;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}
.authCreateAccountInputImage{
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.passwordEyeImage{
    margin-right: 10px;
    cursor: pointer;
}
.forgotPasswordText{
    width: 50%;
    color: #979797;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; /* 150% */
    cursor: pointer;
}

.loginSocial{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.mobile .loginSocial{
    width: 100%;
}

.affirmativeThanksMessage{
    color: var(--Text, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.affirmativeMessage{
    color: var(--Text, #0A0D13);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 35px; 125% */
    letter-spacing: 1px;
    text-align: center;
    padding-inline: 10px;
}

.affirmativeMail{
    color: var(--Text-2, #9DA3A1);
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 12px; 80% */
}
.rejectMessageTitle{
    color: var(--Text, #0A0D13);
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 35px; 125% */
    letter-spacing: 1px;
}

.rejectMessageSpan{
    color: #929292;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    /* line-height: 16px; 114.286% */
}
.rejectTryAgainButton{
    border-radius: 8px;
    background: #1EA9CD;
    display: flex;
}
.rejectTryAgainButtonText{
    color: var(--Text-2, #F3F3F3);
    /* Body Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 12px; 75% */
    padding: 15px 50px;
}