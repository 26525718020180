.how-it-works-steps {
	width: 100%;
	height: clamp(30px, 6vw, 80px);

	display: flex;
	flex-direction: row;
	justify-items: center;
}

.how-it-works-step {
	width: clamp(32px, 6.1vw, 82px);
	height: clamp(30px, 6vw, 80px);

	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;
	align-items: center;
}

.how-it-works-step-img {
	width: clamp(30px, 6vw, 80px);
	height: clamp(30px, 6vw, 80px);

	position: absolute;
	z-index: -1;
	cursor: pointer;
}

.how-it-works-step-text {
	width: clamp(30px, 6vw, 80px);

	text-align: center;
	text-wrap: wrap;

	pointer-events: none;
	cursor: pointer;
}

.how-it-works-step-subtext {
	width: clamp(30px, 6vw, 80px);

	text-align: center;
	text-wrap: wrap;
	font-size: clamp(6px, 1.1vw, 15px);

	pointer-events: none;
	cursor: pointer;
}

.horizontal-line {
	width: 15vw;
	height: 2px;

	position: relative;
	top: clamp(15px, 3vw, 40px);
	z-index: -1;

	border-width: 2px;

	cursor: pointer;
}

.how-it-works-map-wrapper {
	position: relative;
	margin: 20px 0;

	display: flex;
	justify-content: center;
}

.how-it-works-map {
	width: 100%;
	height: 100%;

	position: relative;
	overflow: hidden;
}

.how-it-works-map-img {
	width: 100%;
	height: auto;
	display: block;
	top: 5%;
	position: absolute;
}

.how-it-works-map-text-container {
	width: 100%;

	position: absolute;
	top: 5%;

	pointer-events: none;
}

.how-it-works-map-text {
	width: clamp(25px, 6vw, 90px);
	height: auto;

	position: absolute;
	z-index: 1;

	text-wrap: wrap;
	font-size: clamp(12px, 1.1vw, 20px);
	font-weight: 400;
	letter-spacing: -0.5px;
}

@media screen and (min-width: 890px) {
	.how-it-works-map-wrapper {
		width: 95%;
		height: clamp(100px, 12vw, 180px);
	}

	.how-it-works-map-text-container {
		height: clamp(100px, 9.7vw, 160px);
	}

	#map-text0 {
		width: 250px;
		left: 3%;
		top: 12%;
	}

	#map-text1 {
		left: 3%;
		top: 30%;
	}

	#map-text2 {
		left: 3%;
		top: 60%;
	}

	#map-text3 {
		left: 15%;
		top: 18%;
	}

	#map-text4 {
		width: 250px;
		left: 17%;
		bottom: 20%;
	}

	#map-text5 {
		left: 25%;
		top: 18%;
	}

	#map-text6 {
		left: 34%;
		top: 18%;
	}

	#map-text7 {
		left: 45%;
		top: 18%;
	}

	#map-text8 {
		right: 30%;
		bottom: 28%;
	}

	#map-text9 {
		right: 33%;
		top: 18%;
	}

	#map-text10 {
		right: 19%;
		top: 18%;
	}

	#map-text11 {
		right: 7%;
		top: 18%;
	}

	#map-text12 {
		right: -5%;
		top: 18%;
	}
}

@media screen and (max-width: 890px) {
	.how-it-works-map-wrapper {
		width: 100%;
		height: clamp(100px, 24vw, 200px);
		margin-top: 20px;
	}

	.how-it-works-map-text-container {
		height: clamp(100px, 22vw, 200px);
	}

	.how-it-works-map-text {
		width: 55px;
		text-align: center;
	}

	.how-it-works-map-img {
		height: auto;
	}

	#map-text0 {
		width: 40px;
		top: -30px;
		left: -1vw;
	}

	#map-text1 {
		top: clamp(20px, 6vw, 62px);
		left: 2vw;
	}

	#map-text2 {
		top: clamp(55px, 13.2vw, 118px);
		left: 2vw;
	}

	#map-text3 {
		top: -30px;
		left: 11.5vw;
	}

	#map-text4 {
		width: 120px;
		bottom: clamp(30px, 5.5vw, 55px);
		left: 16vw;
	}

	#map-text5 {
		top: clamp(12px, 3.8vw, 35px);
		left: 20vw;
	}

	#map-text6 {
		top: -30px;
		left: 29vw;
	}

	#map-text7 {
		top: clamp(12px, 3.8vw, 35px);
		left: 36vw;
	}

	#map-text8 {
		bottom: clamp(50px, 8.8vw, 80px);
		right: 29vw;
	}

	#map-text9 {
		top: -15px;
		right: 32vw;
	}

	#map-text10 {
		top: -30px;
		right: 16vw;
	}

	#map-text11 {
		width: 55px;
		top: clamp(12px, 3.8vw, 35px);
		right: 6vw;
	}

	#map-text12 {
		width: 60px;
		top: -15px;
		right: -1.5vw;
	}
}