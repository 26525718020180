.finalRendersContainer {
	position: relative;

	display: flex;
	gap: 45px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.finalRendersSpan {
	width: 65%;

	color: #000;

	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-style: normal;
	font-weight: 300;
	line-height: 26px;
}

.mobile .finalRendersSpan {
	text-align: center;
}

.final-render-swiper-container {
	width: 100%;

	display: flex;
	gap: 15px;
	flex-direction: column;
}

.final-render-top-swiper {
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 1.5vw;

	user-select: none;

	img {
		width: 100%;
		height: 100%;
	}
}

.final-render-bottom-swiper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	user-select: none;

	.swiper-slide {
		filter: brightness(125%) contrast(50%) opacity(85%);
	}

	.swiper-slide-active {
		filter: brightness(100%) contrast(100%) opacity(100%);
	}
}

.final-render-bottom-slide {
	width: 11vw;

	position: relative;
	left: 0.5vw;

	display: flex;
	gap: 8px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	img {
		width: 10vw;
		height: 7.5vw;
		overflow: hidden;

		object-fit: cover;
		object-position: center;
		border-radius: 1.5vw;
	}

	.slider-title {
		padding: 0 5px;

		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
	}
}

@media screen and (max-width: 700px) {
	.slider-title {
		display: none;
	}
}