.brandLogo {
	height: 100%;
	filter: brightness(125%) contrast(50%) grayscale(100%) opacity(65%) saturate(30%);

	transition-duration: 0.3s;
	transition-timing-function: ease-in-out;
	transition-property: filter, transform;

}

.brandLogo:hover {
	transform: scale(1.14);
	filter: brightness(100%) contrast(100%) grayscale(0%) opacity(100%) saturate(100%);

	transition-duration: 0.3s;
	transition-timing-function: ease-in-out;
	transition-property: filter, transform;
}