.contactComponent {
	width: clamp(600px, 80vw, 1440px);

	justify-self: center;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.contactComponent a {
	font-style: normal;
}

.contactElements {
	width: 30vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.contactSpans {
	display: flex;
	flex-direction: column;
	gap: 1vw;
}

.contactHeader {
	margin: 20px 0 0 0;
	font-weight: 700;
	font-size: 2.6rem;
	color: #FFFFFF;
}

.contactSpan {
	font-weight: 400;
	font-size: 23px;
	line-height: 34px;
	color: #FFFFFF;
}

.contactInformations {
	display: flex;
	flex-direction: column;
}

.contactWay {
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;
	gap: 5px;
}

.contactPhone {
	color: #1A8B9A;
	font-family: Inter;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 1px;
	mix-blend-mode: multiply;
}

.contactMail {
	color: #1A8B9A;
	font-family: Inter;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 1px;
	mix-blend-mode: multiply;
}

.contactWayHeader {
	color: #FFF;
	font-family: Inter;
	font-size: 2rem;
	margin: 0;
	font-style: normal;
	font-weight: 600;
}

.contactWaySpans {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.contactWaySpans img {
	fill: #1A8B9A;
	mix-blend-mode: multiply;
}

.contactInputComponent {
	width: clamp(400px, 30vw, 600px);

	margin: 5vh 0;
	padding: 30px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 20px 100px rgba(34, 34, 34, 0.15);
	border-radius: 9px;
	overflow-x: hidden;
}


.inputsRestrictiveContainer {
	width: 70%;
	height: 100%;

	display: flex;
	gap: 20px;
	justify-content: center;
	flex-direction: column;
}

.contactInputHeader {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 110%;
	color: #222222;
}

.form-input,
.input-wrapper .react-tel-input {
	padding: 15px 10px;
	height: 20px;
}

.explanationInput {
	height: 200px;
	padding: 15px 10px;
}

.form-input,
.input-wrapper .react-tel-input,
.explanationInput {
	width: 87.5%;
	background: rgba(255, 255, 255, 0.8);
	border: 1.25px solid #A5A5A5;
	border-radius: 9px;
	font-family: 'Inter';
}

.explanationInput {
	resize: none;
}

.react-tel-input {
	.form-control {
		width: 100%;
		height: 100%;
		border: 0;
		padding-left: 45px;
	}

	.flag-dropdown {
		border: 0;

		background-color: #FFF;
	}

	.selected-flag {
		border-radius: 9px 0 0 9px !important;
	}
}

.submitButton {
	display: flex;
	background: #1EA9CD;
	border-radius: 9px;
	align-items: center;
	justify-content: center;
	width: 40%;
	padding: 15px 0px;
	cursor: pointer;
}

.submitButton:hover {
	background: #15768f;
}

.submitButtonSpan {
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #FFFFFF;
}

.invalid-input-error {
	position: absolute;
	padding-top: 3px;
	padding-left: 5px;

	display: flex;
	justify-content: flex-start;
	align-self: flex-start;
}

.error-border {
	border-color: red;
}

@media screen and (max-width: 890px) {
	.contactComponent {
		width: 90%;
		flex-direction: column;
	}

	.contactElements {
		width: 100%;
	}

	.contactSpans {
		width: 100%;
	}

	.explanationInput {
		height: 300px;
	}

	.contactInputComponent {
		width: 100%;
		height: auto;
	}

	.inputsRestrictiveContainer {
		width: 90%;
	}

	.form-input,
	.input-wrapper .react-tel-input,
	.explanationInput {
		width: calc(100% - 20px);
	}

	.reCaptcha {
		width: 95%;
	}
}