.header-with-line-container {
	width: 98vw;
	position: relative;

	display: flex;
	gap: 25px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.header-line {
	width: 25vw;
	height: 2px;

	position: absolute;

	object-fit: cover;
}

.left {
	left: 0;
}

.right {
	right: 0;
}

.mobile .header-with-line-container {
	flex-direction: column;
	justify-content: center;
}

.lined-header {
	width: 35vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

@media screen and (max-width: 890px) {
	.mobile-hidden {
		display: none;
	}
}