.why-rapid-swiper-component-container {
	height: auto;

	position: relative;
	padding-left: 5vw;
	padding-right: 5vw;

	display: flex;
	gap: 50px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background-color: #EEEEEE;
}

.componentHeaderContainer {
	display: flex;
	gap: 30px;
	flex-direction: column;
	align-items: center;
}

.componentHeader {
	margin: 0;

	color: #000;

	text-align: center;
	text-wrap: balance;
	font-family: Inter;
	font-size: 34px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -1px;
}

.commentContainer {
	width: 100%;
	height: 82%;

	display: flex;
	flex-direction: column;
	align-content: flex-start;
	align-items: flex-start;
}

.mobile .commentContainer {
	gap: 25px;
	align-items: center;
}

.whyRapidCartHeader {
	width: 100%;

	position: relative;
	margin: 0;
	padding: 3%;

	align-content: flex-start;

	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
}

.mobile .whyRapidCartHeader {
	width: 90%;

	text-align: center;

	font-size: 1.2rem;
}

.whyRapidCartSpan {
	position: relative;
	margin: 10px 0 0 0;

	color: #000;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;

	overflow: auto;
	overflow-x: hidden;
	overflow-y: visible;
	scroll-behavior: smooth;
}

.brand-logo-container {
	height: 20%;

	position: relative;

	display: flex;
	align-content: flex-start;
	justify-content: flex-start;
	align-items: center;
}


.swiperCartBrandLogo {
	height: 100%;
}

.comment-owner-container {
	height: 10%;

	position: absolute;
	bottom: 30px;
	padding-left: 15px;

	display: flex;
	gap: 10px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.commentOwnerName {
	margin: 0;
	padding-left: 5px;

	color: #000;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
}

.commentOwnerTitle {
	position: relative;
	margin: 5px 0 0 0;
	padding-left: 5px;

	color: #000;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Inter;
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
}

.whyRapidSwiper {
	width: 100%;

	position: relative;
	padding-bottom: 1vw;

	display: flex;
	gap: 20px;

	.why-rapid-cart {
		width: 300px;
		height: 450px;

		position: relative;
		padding: 30px;
		border-radius: 10px;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		background: #FFF;
	}
}

.swiper-arrow-container {
	width: 105%;

	position: absolute;
	top: 40%;
	left: -2.5%;
	z-index: 3;

	display: flex;
	justify-content: space-between;

	pointer-events: none;
}

.swiperArrow {
	width: 44px;
	height: 44px;

	cursor: pointer;
	pointer-events: all;
}

/* Scrollbar */
.whyRapidCartSpan::-webkit-scrollbar {
	width: 4px;
}

.whyRapidCartSpan::-webkit-scrollbar-thumb {
	background-color: #ddd;
}

.whyRapidCartSpan::-webkit-scrollbar-track {
	background-color: #fff;
}

@media screen and (max-width: 600px) {
	.swiper-arrow-container {
		width: 100%;
		left: 0;
	}
}