.header-component {
	width: 100%;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;

	transition: transform .3s cubic-bezier(0.01, 1.06, 0.82, 0.93);

	background: #000000;

	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
	opacity: 0.9;
}

.header-content-container {
	height: 100%;

	margin: 0 auto;

	display: grid;
	column-gap: 10px;
}

.header-logo-container {
	display: flex;
	align-items: center;

	.header-logo {
		height: 100%;
	}

	img {
		width: auto;
		height: 100%;
	}
}

.header-router-container {
	transform: translateY(30%);

	display: flex;
	align-items: center;
	justify-content: center;

	a {
		color: #FFFFFF;

		font-size: clamp(14px, 1vw, 20px);
		text-decoration: none;
	}

	a:hover {
		color: #1EA9CD;
	}
}

.hidden {
	display: none;
}

@media screen and (min-width: 1050px) {
	.header-component {
		height: 80px;
	}

	.header-content-container {
		width: min(85vw, 1440px);

		grid-template-columns: .3fr 1fr .2fr;
		align-items: center;
		justify-items: flex-start;
	}

	.header-router-container {
		gap: 20px;
	}
}

@media screen and (min-width: 890px) and (max-width: 1050px) {
	.header-component {
		height: 80px;
	}

	.header-content-container {
		width: 87.5vw;

		grid-template-columns: .3fr 1fr .2fr;
		align-items: center;
		justify-items: flex-start;
	}

	.header-router-container {
		gap: 10px;
	}
}

@media screen and (max-width: 890px) {
	.header-component {
		height: clamp(50px, 15vh, 70px);
	}

	.header-content-container {
		width: 96.75vw;

		margin-left: 0;

		grid-template-columns: .1fr 1fr .1fr;
		grid-template-rows: 1fr;
		align-items: center;
		justify-items: flex-start;
	}

	.header-logo-container {
		height: 50%;

		justify-self: flex-start;
		align-self: center;

		gap: 30px;
	}

	.routers-button-wrapper {
		height: 60%;
		padding: 0 1vw 0 4vw;

		img {
			width: auto;
			height: 100%;
		}
	}

	.header-router-container {
		width: 40vw;
		height: min(calc(100vh - 8vw - clamp(50px, 15vh, 70px)), 300px);

		position: absolute;
		top: 0;
		left: 0;
		transform: translateY(clamp(50px, 15vh, 70px));
		padding: 20px;

		grid-area: unset;
		gap: 10px;
		flex-direction: column;

		border-bottom-right-radius: 12px;

		background: #000;
		box-shadow: 0 2px 10px rgba(0, 0, 0, .9), 5px 0 10px rgba(0, 0, 0, .9);

		a {
			width: 80%;
			height: 100%;

			padding: 0 10%;

			display: flex;
			align-items: center;
			justify-content: flex-start;

			font-size: clamp(14px, 5vw, 20px);
			color: #FFFFFF;
			text-decoration: none;
		}
	}
}