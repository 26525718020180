.littleDownloadComponent {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
}

.littleDownloadContainer {
	width: 90%;

	padding: 20px 0vw;
	display: flex;
	gap: 5vw;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media screen and (max-width: 800px) {
	.littleDownloadContainer {
		width: 90%;
		gap: 0;
	}

}

.littleDownloadSpan {
	color: #FFF;
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	font-family: Inter;
}

.littleDownloadSpans {
	display: flex;
	align-items: center;
}

.littleDownloadButtons {
	height: 55px;
	display: flex;
	gap: 20px;
	justify-content: center;

	.button1 {
		width: 45%;

		font-size: 12px;
	}

	.button2 {
		width: 45%;

		font-size: 12px;
	}
}

.mobile .littleDownloadSpan {
	display: none;
}

.mobile .littleDownloadComponentContainer {
	gap: 0px;
	width: 100%;
	flex-direction: column;
	gap: 16px;
}