.noticeContainer {
    margin: 5vh 8vw;
    margin-top: 15vh;
    position: relative;
    display: block;
    align-items: center;
}

.manifestContainer {
    display: block;
    margin: auto;
    margin: 2vh;
}

.articleContainer {
    display: block;
}

.paragraphContainer {
    display: block;
    align-items: center;
    padding: 2vh 2vw;
}

.noticeTitle {
    font-size: x-large;
    text-align: center;
    font-size: xx-large;
}

.title {
    margin: 2vh 0vw -2vh 0vw;
}

.paragraph {
    align-items: start;
    width: 100%;
}