.swiper-shading-container {
	position: relative;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;

	.swiper-shading-left,
	.swiper-shading-right {
		height: 100%;
		width: 35px;
		position: absolute;
		z-index: 2;
	}

	.swiper-shading-left {
		left: -3px;
	}

	.swiper-shading-right {
		right: -3px;
	}
}

@media screen and (max-width: 600px) {
	.swiper-shading-container {

		.swiper-shading-left,
		.swiper-shading-right {
			display: none;
		}
	}
}