.header-normal {
	color: #000;

	text-align: center;

	font-family: Inter;
	font-size: 36px;
	font-style: normal;
	font-weight: 600;

	line-height: 46.5px;
	letter-spacing: -1px;
}

.component-header-large {
	margin: 0;

	color: #000;

	text-align: center;

	font-size: 41.5px;
	font-style: normal;

	line-height: 56.5px;
	letter-spacing: -1px;
}

.component-header-normal {
	margin: 0;

	color: #000;

	text-align: center;

	font-size: 36px;
	font-style: normal;

	line-height: 46.5px;
	letter-spacing: -1px;

}

.component-padding {
	margin: 0;
	padding: 90px 6.5vw 90px 6.5vw !important;
}

.no-margin {
	margin: 0;
}

.column {
	display: flex;
	flex-direction: column;
}

.column-reverse {
	flex-direction: column-reverse;
}

.row {
	display: flex;
	flex-direction: row;
}

.row-reverse {
	display: flex;
	flex-direction: row-reverse;
}

.bold-700 {
	font-weight: 700;
}

.color-white {
	color: #FFF;
}

.align-center {
	justify-self: center;
	align-self: center;
	text-align: center !important;
}

.background-grey {
	background-color: #EEE;
}

@media screen and (min-width: 1050px) {
	.component-header-large {
		font-size: 45px;
	}

	.component-header-normal {
		font-size: 34px;
	}

	.align-left {
		text-align: left !important;
	}
}

@media screen and (min-width: 890px) and (max-width: 1050px) {
	.component-padding {
		padding: 55px 6.5vw 55px 6.5vw !important;
	}

	.component-header-large {
		margin: 0;
		font-size: 36px;
		line-height: 51px;
	}

	.component-header-normal {
		margin: 0;
		font-size: 32px;
		line-height: 40px;
	}

	.align-left {
		text-align: left !important;
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) {
	.component-padding {
		padding: 45px 6.5vw 45px 6.5vw !important;
	}

	.component-header-large {
		margin: 0;
		text-align: center;
		font-size: 36px;
		line-height: 45px;
	}

	.component-header-normal {
		margin: 0;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}
}

@media screen and (max-width: 600px) {
	.component-padding {
		padding: 35px 0 !important;
	}

	.component-header-large {
		margin: 0;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}

	.component-header-normal {
		margin: 0;
		text-align: center;
		font-size: 28px;
		line-height: 35px;
	}
}