.faq-column-wrapper {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 100px;
}

.faq-question {
	width: 100%;

	display: flex;
	gap: 20px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	font-size: 20px;

	user-select: none;

	img {
		width: auto;
		height: 15px;
		transition: ease-in-out .3s
	}

	span {
		font-size: large;
		font-weight: 400;
	}
}

.faq-column {
	height: auto;
	width: 100%;

	padding-top: 20px;
	border-radius: 10px;

	display: flex;
	gap: 20px;
	flex-direction: column;

	background-color: white;
	border-top: 1px solid #ccc;
	border-radius: 0;
	cursor: pointer;
	outline: none !important;
	
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.faq-item {
	display: flex;
	flex-direction: column;

	gap: 25px;
}

.faq-item-divider {
	width: 100%;
	height: 0px;
	border-bottom: 1px inset #ccc;
}

.faq-answer {
	margin: 0 5px;
	padding: 0 10px;

	display: flex;
	gap: 10px;
	flex-direction: column;

	h3 {
		margin: 10px 0 0 0;
		font-size: larger;
	}

	span {
		font-size: medium;
		font-weight: 300;
	}

	ul {
		margin: 0;
	}

	li {
		font-weight: 300;
		font-size: medium;
	}
}

@media screen and (min-width: 890px) {
	.faq-question {
		height: min(40px, 10vw);
	}
}

@media screen and (max-width: 890px) {
	.faq-column {
		width: 90%;
	}

	.faq-question {
		height: max(40px, 4vw);
	}
}