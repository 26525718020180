.readyRoomsComponentContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #FFFFFF;
	padding-left: 13%;
	padding-right: 13%;
	gap: 50px;
}

.mobile .readyRoomsComponentContainer {
	padding: 50px 0 60px 0;
}

.readyRoomsHeaderContainer {
	display: flex;
	width: 82%;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.mobile .readyRoomsHeaderContainer {
	width: 90%;
}

.readyRoomsContainer {
	display: flex;
	flex-wrap: wrap;
	width: 85%;
}

.mobile .readyRoomsContainer {
	width: 90%;
	gap: 30px;
}

.readyRoomItem {
	display: flex;
	flex-direction: column;
	width: 25%;
	margin-top: 40px;
	gap: 20px;
	cursor: pointer;
}

.productItemImage {
	width: 85%;
}

.mobile .productItemImage {
	width: 100%;
	height: 70%;
	object-fit: cover;
	border-radius: 9px;
}

.readyRoomsDescription {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
	width: 85%;
}

.mobile .readyRoomsDescription {
	width: 90%;
}

.readyRoomsDescriptionHeader {
	color: #000;
	font-size: 27px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: -1px;
}

.mobile .readyRoomsDescriptionHeader {
	width: 100%;
	text-align: left;
	font-size: 22px;
}

.readyRoomsDescriptionGalleryContainer {
	position: relative;
	display: flex;
	width: 100%;
}

.readyRoomsDescriptionGallery {
	display: flex;
	gap: 20px;
	overflow-y: hidden;
	overflow-x: hidden;
	width: 100%;
}

.active.readyRoomsDescriptionGalleryContainer::before {
	position: absolute;
	content: "";
	width: 20px;
	height: 100%;
	background: linear-gradient(90deg, rgb(0 0 0 / 15%) 35%, rgba(0, 212, 255, 0) 100%);
	z-index: 2;
	left: 0px;
	top: 0px;
}

.right.readyRoomsDescriptionGalleryContainer::after {
	position: absolute;
	width: 20px;
	height: 100%;
	z-index: 2;
	content: "";
	background: linear-gradient(270deg, rgb(0 0 0 / 15%) 35%, rgba(0, 212, 255, 0) 100%);
	right: 0px;
	top: 0px;
}

.readyRoomsDescriptionGalleryItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 145px;
}

.readyRoomItemDescriptionSpans {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	padding-top: 45px;
}

.readyRoomItemDescriptionSpan {
	color: #797979;
	text-align: left;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
}

.readyRoomsDescriptionGallerySpan {
	color: #000;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
}

.productItemImage.active {
	border-radius: 22px;
	border: 4px solid #3CB8D9;
}

.readyRoomItem.active a {
	color: #1EA9CD;
}

.readyRoomItem.active img {
	border: "2px solid #1EA9CD";
}

.activeNameBlue {
	color: #1EA9CD;
}

.readyRoomsDescriptionGalleryImg {
	width: 74%;
	height: auto;
	display: block;

}

.readyRoomBackroundMini {
	/* background-image: url("../../../../public/assets/img/readyRoomsImages/readyRoomMiniBackground.svg"); */
	width: 145px;
	height: 104px;
	background-size: cover;
	background-position: center;
	position: relative;
	display: inline-block;
}

.swiperReadyRooms {
	width: 100%;
}

.readyRoomDescriptionProductName {
	color: #000;
	font-family: Nunito;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 135%;
}

.productItemArtistName {
	color: #3CB8D9;
	font-feature-settings: 'clig' off, 'liga' off;

	/* menu small */
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
}

.swiper-button-prev {
	display: none;
	/* Başlangıçta sol oku gizler */
}


.readyRoomBigImage {
	width: 100%;
	height: 100%;

	border-radius: 12px;
	object-fit: contain;
}

.mobile .readyRoomBigImage {
	height: 30vh;
	object-fit: cover;
}

/* Responsive Settings */

.mobile .readyRoomItem {
	width: 100%;
	margin-top: 0px;
	height: 30vh;
}