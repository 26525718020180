.productItem {
	width: 100%;

	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: center;

	cursor: pointer;
}

.popupOverlay {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.productItemHeader {
	margin: 0;
	font-style: normal;
	font-weight: 700;
	color: #000000;
	text-align: center;
	font-family: 'Nunito' !important;
}

.left.productItemHeader {
	text-align: left;
}

.active.productItemHeader {
	color: #1EA9CD;
}

.productsImage {
	width: 100%;
	height: auto;
	border-radius: 12px;
	user-select: none;
}

.popup {
	width: 90%;
	height: 80vh;
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	align-items: center;
	background: #EFEFEF;
	border-radius: 12px;
	gap: 30px;
	z-index: 8;
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
	left: 50%;
	top: 50%;
	transform: translate(-50%, -43%);
}

.sugarModelViewerContainer {
	width: 100%;
	height: 100%;
}

.closeButtons {
	position: absolute;
	right: 8px;
	top: 7px;
	z-index: 8;
	cursor: pointer;
}

.sugar-model-viewer-container {
	border-radius: 12px;
}

.product-item-container {
	width: 100%;

	display: grid;
	gap: 30px;
}

@media screen and (min-width: 890px) {
	.product-item-container {
		grid-template-columns: repeat(4, 1fr);
	}

	.productItemHeader {
		font-size: 20px;
	}
}

@media screen and (min-width: 600px) and (max-width: 890px) {
	.product-item-container {
		grid-template-columns: repeat(3, 1fr);
	}

	.productItemHeader {
		font-size: 15px;
		text-align: left;
	}
}

@media screen and (max-width: 600px) {
	.product-item-container{
		width: 95%;
		grid-template-columns: repeat(2, 1fr);
		gap: 15px;
	}

	.popup {
		flex-direction: column-reverse;
		padding: 20px 10px;
	}

	.productItemHeader {
		font-size: 14px;
		text-align: left;
	}
}