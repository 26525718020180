.WhatsNewContainer {
	position: relative;

	display: flex;
	gap: 50px;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	background: #EEEEEE;
}

.WhatsNewContainer a {
	font-style: normal;
	text-align: center;
	color: #000000;
}

.whatsNewHeader {
	font-weight: 700;
	font-size: 39px;
	line-height: 49px;
	position: relative;
	width: 50%;
}

.whatsNewSpan {
	position: relative;
	width: 95%;
	text-align: center;
	font-weight: 250;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

.whatsNewSpan.library {
	width: 82%;
	text-align: center;
}

.mobile .whatsNewSpan.library {
	width: 90% !important;
	text-align: left;
}

.whatsNewSpans {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
}

.main.whatsNewSpans {
	width: 60%;
}

.mobile .main.whatsNewSpans {
	width: 100%;
}

.whatsNewElements {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main.whatsNewElements {
	width: 60%;
}

.mobile .main.whatsNewElements {
	width: 100%;
}

.whatsNewElement {
	width: 100%;
}

/* Responsive Tablet Settings */

.tablet .WhatsNewContainer {
	height: 1000px;
}

/* Responsive Mobile Settings */

.mobile .WhatsNewContainer {
	height: auto;
	padding: 35px;
}

.mobile .whatsNewElements {
	bottom: 50px;
}

.mobile .whatsNewHeader {
	width: 80%;
}

.mobile .whatsNewSpan {
	width: 100% !important;
	text-align: left;
}

.mobile .WhatsNewContainer a {
	text-align: left;
}