.language-wrapper {
	height: 50%;
	grid-area: 1 / 3 / 2 / 4;

	transform: translateY(5px);

	.language-flag {
		width: auto;
		height: 10%;
	}
}

.selected-language-container {
	width: 100%;
	height: 100%;

	padding: 0 10px;

	display: flex;
	gap: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.language-dropdown {
	position: absolute;
	z-index: 6;
	padding: 20px;

	display: flex;
	gap: 17px;
	justify-content: center;
	flex-direction: column;

	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;

	background: #000;

	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
}

.language-option {
	cursor: pointer;
	display: flex;

	img {
		width: auto;
		height: 100%;
	}
}

.dropdown-chevron {
	cursor: pointer;
}

.language-dropdown div {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
}

.language-name {
	color: white;
	padding-left: 10px;
}

@media screen and (min-width: 890px) {
	.language-dropdown {
		top: 100%;
		left: 0;
	}
}

@media screen and (min-width: 450px) and (max-width: 890px) {
	.language-dropdown {
		top: 120%;
		right: -7%;
	}
}

@media screen and (max-width: 450px) {
	.language-name {
		display: none;
	}

	.language-dropdown {
		top: 100%;
		right: 4%;
	}
}